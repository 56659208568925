import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { GrupoEconomico } from '../interfaces/grupo-economico';
import { Page } from '../interfaces/page';
import { IntegranteGrupoEconomico } from '../interfaces/integrante-grupo-economico';
import { Personal } from '../interfaces/personal';
import { Destinatario } from '../interfaces/destinatario';

@Injectable({
  providedIn: 'root'
})
export class GrupoEconomicoService {

  recurso: string = '/gruposeconomicos';
  recursoDestinatarios = '/destinatario';

  constructor(private http: HttpClient) { }

  public findAll({ pageNumber = 1, pageSize = 10000, orderCriteria = 'ASC', orderFile = 'geDesc', habilitado = 'TODOS' } = {}): Observable<Page<GrupoEconomico>> {
    return this.http.get<Page<GrupoEconomico>>(`${environment.apiEndpoint}/gruposeconomicos?pageNumber=${pageNumber}&pageSize=${pageSize}&orderCriteria=${orderCriteria}&orderFile=${orderFile}&habilitado=${habilitado}`)
  }
  public findReferentes() {
    let endpoint = '/responsables';
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findAllReferentes() {
    let endpoint = '/personal?habilitado=S';
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findGrupoEconomicoById(id: number) {
    let endpoint = this.recurso + '/' + id;
    return this.http.get(environment.apiEndpoint + endpoint);
  }
  public findClientes(id: string, habilitado: string): Observable<Page<any>> {
    let endpoint = '/clientes/findByGrupo/' + id + '?habilitado=' + habilitado;
    return this.http.get<Page<any>>(environment.apiEndpoint + endpoint);
  }
  public findClientesEventuales(id: string, habilitado: string, clienteEventual?: string) {
    let endpoint = '/clientes/findByGrupo/' + id + '?habilitado=' + habilitado + '&clienteEventual=' + clienteEventual;
    return this.http.get(environment.apiEndpoint + endpoint);
  }

  public createGrupoEconomico(grupoEconomico: GrupoEconomico) {
    let endpoint = this.recurso;
    return this.http.post(environment.apiEndpoint + endpoint, grupoEconomico);
  }

  public editGrupoEconomico(grupoEconomico: GrupoEconomico) {
    let endpoint = this.recurso + '/' + grupoEconomico.id;
    return this.http.put(environment.apiEndpoint + endpoint, grupoEconomico);
  }
  public editIntegrantes(id: string, integrantes: Array<IntegranteGrupoEconomico>) {
    return this.http.put(`${environment.apiEndpoint}/gruposeconomicos/${id}/clientes`, integrantes);
  }
  public findPersonalAsignado(id: number): Observable<Array<Personal>> {
    return this.http.get<Array<Personal>>(`${environment.apiEndpoint}/gruposeconomicos/${id}/personal`);
  }

  public findDestinatarios(id: number): Observable<Destinatario> {
    return this.http.get<Destinatario>(`${environment.apiEndpoint}${this.recursoDestinatarios}/${id}`);
  }
  public addDestinatario(destinatario: Destinatario): Observable<Destinatario> {
    return this.http.post<Destinatario>(`${environment.apiEndpoint}${this.recursoDestinatarios}`, destinatario);
  }
  public editDestinatario(destinatario: Destinatario): Observable<Destinatario> {
    return this.http.put<Destinatario>(`${environment.apiEndpoint}${this.recursoDestinatarios}/${destinatario.id}`, destinatario);
  }
}
